<template>
  <div>
    <PPage :breadcrumbs="[{content: 'Customers', onAction: handleBack}]"
           title="Manage Quotation"
    >
      <PDisplayText size="small" slot="primaryAction"><strong>Grand Total</strong> <span>&#8377;</span>
        {{ $global.numberToLocationString(displayTotal) }}
      </PDisplayText>
      <PLayout>

        <PLayoutAnnotatedSection title="Customer Details">
          <div slot="description">
            <PTextStyle>Click on save button to create and continue with quotation.
            </PTextStyle>
          </div>
          <PCard sectioned>
            <PFormLayout autocomplete="off">
              <PFormLayoutGroup condensed>
                <PTextField
                  label="Lead for quotation"
                  id="lead"
                  type="text"
                  :disabled="true"
                  :value="lead"
                />
                <PTextField
                  label="Event for quotation"
                  id="event"
                  type="text"
                  :disabled="true"
                  :value="event"
                />
                <PTextField
                  label="GST no"
                  id="gst_no"
                  type="text"
                  v-model="formFields.gst_no"
                />
              </PFormLayoutGroup>
            </PFormLayout>
          </PCard>
        </PLayoutAnnotatedSection>
        <PLayoutSection class="preferences">
          <PCard id="fnb" title="Food & Beverages" v-if="formFields.id">
            <fnb @update="handleUpdate" ref="fnb" :is_invoice="is_invoice" :editData="preferenceItems.fnb"></fnb>
          </PCard>
          <PCard id="decors" title="Decors" v-if="formFields.id">
            <decors @update="handleUpdate" :is_invoice="is_invoice" ref="decors" :editData="preferenceItems.decors"></decors>
          </PCard>
          <PCard id="other_charges" title="Other Charges" v-if="formFields.id">
            <other-charges @update="handleUpdate" :is_invoice="is_invoice" ref="other_charges"
                           :editData="preferenceItems.other_charges"></other-charges>
          </PCard>
          <PCard id="rooms" title="Rooms" v-if="formFields.id">
            <rooms @update="handleUpdate" :is_invoice="is_invoice" ref="rooms" :editData="preferenceItems.rooms"></rooms>
          </PCard>
          <PCard v-if="formFields.id" id="grand_total" class="mt-15">
            <PLayout>
              <PLayoutSection>
                <b-container fluid>
                  <b-row>
                    <b-col sm="12" class="text-right">
                      <table class="table ml-auto table-bordered total-table">
                        <tr>
                          <th>Discount Amount</th>
                          <td>
                            <b-form-input
                              :state="((getError('discount_amount') ? false : null))"
                              @keyup="handleTotal"
                              v-model="formFields.discount_amount"
                              v-numericOnly.keyup
                            ></b-form-input>
                          </td>
                        </tr>
                        <tr>
                          <th>GST Amount</th>
                          <td>
                            <PDisplayText><span>&#8377;</span>
                              {{ $global.numberToLocationString(totalGst) }}
                            </PDisplayText>
                          </td>
                        </tr>
                        <tr>
                          <th>Grand Total</th>
                          <td>
                            <PDisplayText><span>&#8377;</span>
                              {{ $global.numberToLocationString(displayTotal) }}
                            </PDisplayText>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-container>
              </PLayoutSection>
            </PLayout>
          </PCard>
        </PLayoutSection>
      </PLayout>


      <PPageActions
        :primary-action="{content:'Save', onAction: handleSubmit}"
        :secondary-action="{content:'Cancel', onAction: handleBack}"
      />
    </PPage>
  </div>
</template>

<script>
import moduleMixin from "./module.mixin";
import {mapState} from "vuex";
import {bus} from "@/main";
import handleError from "@/util/handleError";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import Fnb from "./preferences/fnb/index.vue";
// import FnbExtras from "./preferences/fnbExtras/index.vue";
import Decors from "./preferences/decors/index.vue";
import OtherCharges from "./preferences/otherCharges/index.vue";
import Rooms from "./preferences/rooms/index.vue";

export default {
  mixins: [moduleMixin, handleError, totalAmountMixin],
  components: {
    Fnb,
    // FnbExtras,
    Decors,
    OtherCharges,
    Rooms
  },
  async mounted() {
    if (this.$route.params && this.$route.params.id) {
      await this.handleEditOperation(this.$route.params.id);
    }
  }
};
</script>

<style scoped lang="scss">
.total-table {
  border-radius: 8px;
}
</style>

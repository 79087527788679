<template>
  <b-overlay rounded="sm">
    <PLayout>
      <PLayoutSection>
        <b-container fluid>
          <b-row>
            <div class="table-responsive mt-5">
                    <span
                      class="text-danger">
                      {{ ( formErrors.has('preferences') ) ? "Please select one record." : "" }}
                    </span>
              <table
                class="v-data-table table table-vertical-center theme--light"
                width="100%">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th style="width: 10%">Rate</th>
                    <th style="width: 10%" v-if="is_invoice">GST</th>
                    <!--                    <th class="text-right">Sub Total</th>-->
                    <th style="width: 10%" class="text-right">Total</th>
                    <th style="width: 30%">Remark</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(decor,index) in formFields.decors.length">
                    <td>
                      <b-form-datepicker
                        v-model="formFields.decors[index].date"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        @change="handleChangeInput"
                        :class="((formErrors.all()[`decors.${index}.date`] ?  'error-input'  : null))"
                      ></b-form-datepicker>
                    </td>
                    <td>
                      <b-form-select
                        value-field="id"
                        text-field="label"
                        @change="handleSelectDecor(index)"
                        :options="_.map(dropdowns.decors,(i) =>{
                                                        return{id:i.id,label:i.name}})"
                        v-model="formFields.decors[index].object_id"
                        :class="((formErrors.all()[`decors.${index}.object_id`] ?  'error-input'  : null))"
                      >
                      </b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        :class="((formErrors.all()[`decors.${index}.rate`] ?  'error-input'  : null))"
                        @keyup="handleChangeInput"
                        v-model="formFields.decors[index].rate"
                        v-numericOnly.keyup
                      ></b-form-input>
                    </td>
                    <td v-if="is_invoice">
                      <b-form-input
                        :class="((formErrors.all()[`decors.${index}.gst`] ?  'error-input'  : null))"
                        @keyup="handleChangeInput"
                        v-model="formFields.decors[index].gst"
                        v-numericOnly.keyup
                      ></b-form-input>
                    </td>
                    <!--                    <td class="text-right" width="10%">-->
                    <!--                      {{-->
                    <!--                        $global.numberToLocationString(totalAmountPreferences(formFields.decors[index]).amount)-->
                    <!--                      }}-->
                    <!--                    </td>-->
                    <td class="text-right">
                      {{
                        $global.numberToLocationString(totalAmountPreferences(formFields.decors[index]).total)
                      }}
                    </td>
                    <td>
                      <b-form-input
                        @change="handleChangeInput"
                        v-model="formFields.decors[index].remark"
                      ></b-form-input>
                    </td>
                    <td class="text-center">
                      <i class="fa fa-trash mr-2" @click="removeDecor(index)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-row>
        </b-container><!--/b-container-->
        <PStack>
          <PStackItem fill class="p-7">
            <PButtonGroup>
              <PButton primary @click="addMore">
                Add More
              </PButton>
              <!--              <PButton primary @click="handleSubmitOperation">-->
              <!--                Save-->
              <!--              </PButton>-->
            </PButtonGroup>
          </PStackItem>
          <PStackItem class="p-7">
            <PDisplayText size="small">
              <strong>Total</strong> <span>&#8377;</span>
              {{ $global.numberToLocationString(totalAmountPreferences(formFields.decors).total) }}
            </PDisplayText>
          </PStackItem>
        </PStack>
      </PLayoutSection>
    </PLayout>
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { bus } from "@/main";
import totalAmountMixin from "@/assets/js/components/totalAmountMixin";

const DEFAULT_FORM_STATE = {
  decors: [],
};

export default {
  mixins: [totalAmountMixin],
  props: ["editData", "is_invoice"],
  data() {
    return {
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      dropdowns: {
        decors: [],
      },
    };
  },
  methods: {
    async removeDecor(index) {
      this.$delete(this.formFields.decors, index);
      if ( !this.formFields.decors.length ) {
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
      this.$emit('update', 'decors', this.formFields.decors);
    },
    async handleSelectDecor(index) {
      this.formErrors = new Error({});

      const data = this.formFields.decors[index];
      const fnbIndex = _.findIndex(this.dropdowns.decors, (i) => i.id == data.object_id);

      if ( fnbIndex !== -1 ) {
        const fnb = this.dropdowns.decors[fnbIndex];
        this.formFields.decors[index].rate = fnb.rate;
        this.formFields.decors[index].gst = fnb.gst;

        const changeTotal = this.totalAmountPreferences(this.formFields.decors);
        this.formFields.decors[index].sub_total = this.$global.numberToLocationString(changeTotal.amount);

        this.handleChangeInput();
      }
    },
    addMore() {
      this.formFields.decors.push({
        object_id: null,
        rate: 0,
        gst: 0,
        label: null,
        date: null,
        remark: null
      });
    },
    async handleDeleteOperation(id) {
      try {
        const response = await request({
          method: 'post',
          url: `/quotations/preferences/delete`,
          data: {
            id: id
          }
        });
      } catch (e) {
      }
    },
    async getDecors() {
      this.formFields.decors = [];
      try {
        const response = await request({
          url: `/dropdowns/decors`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.decors = data;
      } catch (e) {

      } finally {
        _.map(this.editData, (item, index) => {
          this.formFields.decors.push({
            object_id: item.object_id,
            rate: item.rate,
            gst: item.gst,
            date: item.date,
            id: item.id,
            remark: item.remark
          });
        });
      }
    },
    handleChangeInput() {
      this.$emit('update', 'decors', this.formFields.decors);
    },
    displayErrors(errors) {
      this.formErrors = new Error(errors);
    }
  },
  async mounted() {
    this.formFields.decors = [];
    this.formErrors = new Error({});
    await this.getDecors();
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
